import React from "react";
import styled from 'styled-components'
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// const href = '#/swap'

const Container = styled.div`
  width: 100%;
  background: #27262C;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 20px 40px;
`
const FooterTitle = styled.div`
  padding: 30px 0 22px;
  border-bottom: 1px solid #453A53;
  display: flex;
  align-items: center;

  img {
    display: block;
    width: 28px;
  }

  span {
    font-size: 18px;
    font-family: FZCuYuan-M03S-Regular, FZCuYuan-M03S;
    color: #FFFFFF;
    line-height: 21px;
    padding-left: 12px;
    font-weight: bold;
  }
`
const FooterMiddle = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid #453A53;
`
const FooterMiddleItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:nth-child(1) {
    margin-bottom: 16px;
  }
`
const FooterMiddleItemInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Kanit, sans-serif;
  color: #B6ACD0;
  img {
    display: block;
    width: 24px;
  }
  span {
    padding-left: 12px;
  }
`
// const FooterMiddleItembtn = styled.a`
//   display: block;
//   line-height: 32px;
//   background: #3EC8D4;
//   box-shadow: 0px 1px 0px 0px #44ACB9;
//   border-radius: 16px;
//   padding: 0 12px;
//   display: flex;
//   align-items: center;
//   font-size: 16px;
//   font-family: Kanit, sans-serif;
//   font-weight: bold;
//   color: #FFFFFF;
//   img {
//     display: block;
//     width: 14px;
//     margin-left: 9px;
//   }
// `
const FooterBot = styled.div`
  overflow: hidden;
  padding: 22px 0;
  font-family: Kanit, sans-serif;
`
const FooterBotTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #8660DC;
  line-height: 25px;
  margin-bottom: 16px;
`
const FooterBotItem = styled.a`
  font-size: 16px;
  font-weight: bold;
  color: #B6ACD0;
  line-height: 25px;
  margin-bottom: 14px;
  display: block;
  img {
    display: block;
    width: 30px;
    height: 30px;
  }
`

export default function FooterContainer({lang}: {lang: string}) {
  const langauage = lang
  const history = useHistory()
  function toAddtoken() {
    history.push(`/addtoken`)
  }
  // function checkDevelop() {
  //   console.log(langauage);
  // }
  const { t } = useTranslation()
  // <img src="./images/pancake.png" alt=""/>
  return (
    <Container>
      <FooterTitle>
        <span>LP Swap</span>
      </FooterTitle>
      <FooterMiddle>
        {/* <FooterMiddleItem>
          <FooterMiddleItemInfo>
            <img src="./images/cake.png" alt=""/>
            <span />
          </FooterMiddleItemInfo>
          <FooterMiddleItembtn href={href}>{t('购买')}LPC <img src="./images/arrow-right.png" alt=""/></FooterMiddleItembtn>
        </FooterMiddleItem> */}
        <FooterMiddleItem>
          <FooterMiddleItemInfo>
            <img src="./images/lang.png" alt=""/>
            <span>{langauage}</span>
          </FooterMiddleItemInfo>
        </FooterMiddleItem>
      </FooterMiddle>
      <FooterBot>
        <FooterBotTitle>{t('关于')}</FooterBotTitle>
        <FooterBotItem onClick={toAddtoken}>{t('提交代币')}</FooterBotItem>
        <FooterBotItem>{t('品牌')}</FooterBotItem>
        <FooterBotItem type="download" href={langauage === '简体中文' ? './development/zh-cn.docx' : './development/en.docx'}>{t('开发者')}</FooterBotItem>
        <FooterBotItem type="download" href="./development/AuditReport.pdf">{t('审计')}</FooterBotItem>
        <FooterBotItem href="https://x.com/LPswap_DEX">
          <img src="./images/x.png" alt="" />
          {/* {t('推特')} */}
        </FooterBotItem>
        {/* <FooterBotItem href="https://t.me/LPswap_LPC">
          <img src="./images/Telegram.webp" alt="" />
        </FooterBotItem> */}
      </FooterBot>
    </Container>
  )
}
